<template>
<div class="ree">
  <a href="https://lutentime.tumblr.com/"><p>tumblr</p></a>
  <a href="https://www.instagram.com/lutentime/"><p>instagram</p></a>
  <a href="https://bsky.app/profile/lutentime.bsky.social"><p>bluesky</p></a>
  <a href="https://twitter.com/lutentime"><p>twitter</p></a>
  <a href="https://www.youtube.com/@lutentime"><p>youtube</p></a>
  <ColorPicker format="rgb"
  shape="circle"
  v-model:pure-color="pureColorA"
  disableAlpha="true"
  @pureColorChange="changecolor()"
  picker-type="chrome"/>
  <ColorPicker
  format="rgb"
  shape="circle"
  v-model:pure-color="pureColorB"
  disableAlpha="true"
  @pureColorChange="changecolor()"
  picker-type="chrome"/>
</div>
</template>

<script>
import tinycolor from 'tinycolor2';

export default {
  name: 'HelloWorld',
  data() {
    return {
      showColors: false,
      A: {r: 244, g: 194, b: 194},
      B: {r: 242, g: 212, b: 162},
      pureColorA: "rgb(244, 194, 194)",
      pureColorB: "rgb(242, 212, 162)",
    }
  },
  methods: {
    changecolor(){
        var text = this.getTextColor(tinycolor(this.pureColorA), tinycolor(this.pureColorB), true);
        var hovText = this.getTextColor(tinycolor(this.pureColorA), tinycolor(this.pureColorB), false);
        this.$emit('colorChange', {bgA: this.pureColorA, bgB: this.pureColorB, text, hovText})
    },
    getTextColor(A, B, inv){
      console.log(A);

      var r = (+A._r + +B._r)/2;
      var g = (+A._g + +B._g)/2;
      var b = (+A._b + +B._b)/2;

      if(inv){
        r = Math.abs(255 - r);
        g = Math.abs(255 - g);
        b = Math.abs(255 - b);
      }

      return tinycolor({r, g, b}).toString();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ree{
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, var(--bg-color-a) 0%, var(--bg-color-b) 100%);
  height: 100vh;
  width: 100vw;
  margin: 0;
  vertical-align: top;
}
.material-icons.colorCircle{
   border-radius: 50%;
   cursor: pointer;
   border-style: dotted;
   border-color: rgba(0, 0, 0, 0.3);
   display: inline;
}
.slidr{
  accent-color: rgba(255, 0, 0);
}
.slidg{
  accent-color: rgba(0, 255, 0);
}
.slidb{
  accent-color: rgba(0, 0, 255);
}
input{
  margin: 5px;
  color:aliceblue;
  font-family: "Comic Sans";
  width: 4ch;
  font-size: 24px;
  background-color: rgba(125, 125, 125, 0.1);
  border: none;
  border-radius: 2px;
}
input:focus{
  outline: none;
}
input::placeholder{
  color: rgba(240, 248, 255, 0.3);
}
input[type="range" i]{
  appearance: none;
}

a:link, a:visited{
   text-decoration: none;
    color: var(--text-color)
}
a:hover, a:active{
    color: var(--hov-text-color)
}
button{
  margin: 5px;
  color:aliceblue;
  font-family: "Comic Sans";
  font-size: 24px;
  background-color: rgba(125, 125, 125, 0.1);
  border: none;
  border-radius: 2px;
}
h1 {
  display: inline;
  margin: 20px;
}
img{
  height: 30vh;
  width: auto;
}
</style>
